body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Basic overrides */
html,
body {
  height: 100%;
  background-color: #333;
}

body {
  color: #fff;
  text-align: center;
  background-image: url('bg.png');
}

a,
a:focus,
a:hover {
  color: #fff;
  text-decoration: none;
}

.site-wrapper {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

header,
main,
footer {
  padding: 2rem;
}

main {
  vertical-align: middle;
}

footer {
  vertical-align: bottom;
  color: #aaa;
}

.nav a {
  color: #aaa;
}
.nav a:hover {
  color: #fff;
}

/* Layout tweaks for desktop size */
@media (min-width: 56em) {
  .site-wrapper {
    width: 48em;
    margin: 0 auto;
  }

  header .brand {
    float: left;
    margin-bottom: 0;
  }

  header .nav {
    float: right;
  }

  .header-wrapper,
  .main-wrapper,
  .footer-wrapper {
    display: table-row;
  }

  header,
  main,
  footer {
    display: table-cell;
  }
}
